@font-face {
  font-family: 'AvenirNextLTPro-Demi';
  src: url("../webfonts/3810A2_2_0.eot");
  src: url("../webfonts/3810A2_2_0.eot?#iefix") format("embedded-opentype"), url("../webfonts/3810A2_2_0.woff2") format("woff2"), url("../webfonts/3810A2_2_0.woff") format("woff"), url("../webfonts/3810A2_2_0.ttf") format("truetype");
}
@font-face {
  font-family: 'AvenirNextLTPro-Regular';
  src: url("../webfonts/3810A2_A_0.eot");
  src: url("../webfonts/3810A2_A_0.eot?#iefix") format("embedded-opentype"), url("../webfonts/3810A2_A_0.woff2") format("woff2"), url("../webfonts/3810A2_A_0.woff") format("woff"), url("../webfonts/3810A2_A_0.ttf") format("truetype");
}
